import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-05a46591"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "dis-sp"
};
var _hoisted_2 = ["src"];
export default {
  setup: function setup(__props) {
    var urls = require("@/assets/logoHeader.png");

    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
        class: "imgs",
        src: _unref(urls)
      }, null, 8, _hoisted_2)]);
    };
  }
};