import { createRouter, createWebHashHistory } from "vue-router";
import index from "../layout/index.vue";
const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    children: [
      {
        path: "/Support",
        name: "Support",
        component: () => import("@/views/lindoInfo"),
        meta: { title: "Support" },
        children: [
          {
            path: "/SupportFAQ",
            name: "SupportFAQ",
            component: () => import("../views/lindo"),
            meta: { title: "SupportFAQ" },
            children: [
              {
                path: "/SupportFAQ/:id",
                name: "lindoSupport",
                component: () => import("@/views/lindoSupport"),
                meta: { title: "FAQInfo" },
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to.fullPath);
  if (to.fullPath === "/") {
    next("/Support");
  } else {
    next();
  }
});

export default router;
