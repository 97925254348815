import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-66a6fe9b"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "common-layout"
};
import { useRoute } from "vue-router";
import Headers from "./Header";
import Footers from "./Footer"; // import Header from "./Header/index.vue";
// import Footer from "./Footer/index.vue";

export default {
  setup: function setup(__props) {
    var route = useRoute();
    return function (_ctx, _cache) {
      var _component_el_header = _resolveComponent("el-header");

      var _component_router_view = _resolveComponent("router-view");

      var _component_el_main = _resolveComponent("el-main");

      var _component_el_footer = _resolveComponent("el-footer");

      var _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_header, {
            class: "header"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_unref(Headers))];
            }),
            _: 1
          }), _createVNode(_component_el_main, null, {
            default: _withCtx(function () {
              return [_createVNode(_component_router_view, null, {
                default: _withCtx(function (_ref) {
                  var Component = _ref.Component,
                      route = _ref.route;
                  return [_createVNode(_Transition, {
                    name: "fade-transform",
                    mode: "out-in"
                  }, {
                    default: _withCtx(function () {
                      return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                        key: route.path
                      }))];
                    }),
                    _: 2
                  }, 1024)];
                }),
                _: 1
              })];
            }),
            _: 1
          }), _createVNode(_component_el_footer, null, {
            default: _withCtx(function () {
              return [_createVNode(_unref(Footers))];
            }),
            _: 1
          })];
        }),
        _: 1
      })]);
    };
  }
};