import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-3a278580"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "navbar"
};
var _hoisted_2 = {
  class: "navbar-footer"
};
import Breadcrumb from "./components/breadcrumb.vue";
import HeaderLog from "./components/headerLog.vue";
export default {
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(HeaderLog)]), _createElementVNode("div", _hoisted_2, [_createVNode(Breadcrumb)])], 64);
    };
  }
};