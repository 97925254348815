import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1aa2e3d6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "body-header"
};
var _hoisted_2 = {
  key: 0,
  class: "redirect"
};
var _hoisted_3 = ["onClick"];
import { watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  setup: function setup(__props) {
    var route = useRoute();
    var router = useRouter();
    var breadcrumbList = ref([]);

    var initBreadcrumbList = function initBreadcrumbList() {
      console.log(route.matched);
      breadcrumbList.value = route.matched;
    };

    var handleRedirect = function handleRedirect(path) {
      console.log(path);
      router.push(path);
    };

    watch(route, function () {
      initBreadcrumbList();
    }, {
      deep: true,
      immediate: true
    });

    var openSupport = function openSupport() {
      window.open("https://www.lindolife.com/pages/support", "_blank");
    };

    return function (_ctx, _cache) {
      var _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

      var _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_breadcrumb, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_breadcrumb_item, null, {
            default: _withCtx(function () {
              return [_createElementVNode("span", {
                class: "no-redirect",
                onClick: openSupport
              }, "lindolife")];
            }),
            _: 1
          })];
        }),
        _: 1
      }), _createVNode(_component_el_breadcrumb, {
        separator: "/",
        class: "div-body"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(breadcrumbList.value, function (item, index) {
            return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
              key: index
            }, {
              default: _withCtx(function () {
                return [(breadcrumbList.value.length > 1 ? index === breadcrumbList.value.length - 1 : index === breadcrumbList.value.length) ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.meta.title), 1)) : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: "no-redirect",
                  onClick: function onClick($event) {
                    return handleRedirect(item.path);
                  }
                }, _toDisplayString(item.meta.title), 9, _hoisted_3))];
              }),
              _: 2
            }, 1024);
          }), 128))];
        }),
        _: 1
      })]);
    };
  }
};